@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: sans-serif; 
}

/* hide increase / decrease button of number input */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

/* hide scroll indicator */
::-webkit-scrollbar {
    display: none;
}

@font-face {
    font-family: 'KingYoma';
    src: local('KingYoma'), url('../public/assets/fonts/king_yoma.ttf') format('truetype');
}
.font-kingyoma {
    font-family: 'KingYoma', sans-serif;
}

@font-face {
    font-family: 'Myawaddy19';
    src: local('Myawaddy19'), url('../public/assets/fonts/myawaddy_19.ttf') format('truetype');
}
.font-myawaddy {
    font-family: 'Myawaddy19', sans-serif;
}


@font-face {
    font-family: 'Selfie';
    src: local('Selfie'), url('../public/assets/fonts/Selfie_Regular.otf') format('truetype');
}
.font-selfie {
    font-family: 'Selfie', sans-serif;
}

@font-face {
    font-family: 'Lemon';
    src: local('Lemon'), url('../public/assets/fonts/Lemon-Regular.ttf') format('truetype');
}
.font-lemon {
    font-family: 'Lemon', sans-serif;
}

@font-face {
    font-family: 'Ballpark';
    src: local('Ballpark'), url('../public/assets/fonts/Ballpark_Regular.ttf') format('truetype');
}
.font-ballpark {
    font-family: 'Ballpark', sans-serif;
}

button {
    cursor: pointer;
}

button:active {
    opacity: 50%;
}

button:disabled {
    opacity: 1;
    cursor: not-allowed;
}

.shadow-t {
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
    /* Add any other styles you need for your component */
}